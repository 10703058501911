/* width */
.preview ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.preview ::-webkit-scrollbar-track {
  background: #888;
  border-radius: 5px;
}

/* Handle */
.preview ::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

/* Handle on hover */
.preview ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
