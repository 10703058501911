.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  border-radius: 12px;
  height: 400px;
  min-width: 20%;
  background-color: rgb(218, 218, 218);
}
.login-title {
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 5px;
  text-transform: uppercase;
}
form {
  display: flex;
  margin-top: 1rem;
  font-size: 0.85rem;
  font-weight: 600;
  width: 200px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
input {
  border: none;
  padding: 0.35rem;
  font-family: 'Inter', sans-serif;
  margin: 0.75rem 0;
  border-radius: 4px;
  width: 100%;
}
button {
  cursor: pointer;
  border: none;
  align-self: center;
  margin-top: 30px;
  font-size: 1rem;
  padding: 0.65rem;
  border-radius: 4px;
  color: white;
  background-color: rgb(161, 161, 161);
}
.login-register {
  margin: 2rem 1rem 0 1rem;
  font-size: 1rem;
}
.login-register-link {
  text-decoration: none;
  cursor: pointer;
  margin-top: 1rem;
  color: rgb(105, 105, 105);
  font-weight: 700;
}

.login-btn {
  border: none;
  outline: none;
  background: #03a9f4 !important;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.input-box {
  position: relative;
  width: 100%;
}

.input-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  margin-bottom: 30px;
  color: #fff;
  border: 1px solid #fff;
  border: none;
  border-bottom-style: solid;
  outline: none;
  letter-spacing: 1px;
  background: transparent;
}

.input-box label {
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.input-box input:focus ~ label,
.input-box input:valid ~ label {
  top: -18px;
  left: 0;
  color: #03a9f4;
  font-size: 12px;
}
