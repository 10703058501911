* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

body {
  background: white;
}

body,
.App {
  min-height: 100vh;
  margin: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 1.15rem;
  font-family: 'Inter', sans-serif;
}
