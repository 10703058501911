.navbar-container {
  display: flex;
  padding: 0 1.5rem;
  justify-content: space-between;
  background-color: #212121;
  align-items: center;
  height: 68px;
}
.navbar-container > * {
  margin: 0 1.25rem;
  text-decoration: none;
  font-weight: 600;
  color: white;
}

.navbar-home:hover,
.navbar-logout:hover {
  opacity: 0.8;
}

.navbar-login {
  /* color:rgb(163, 162, 162); */
  display: none;
}
/* .navbar-register{
    color:rgb(255, 255, 255);
    background-color: rgb(150, 150, 150);
    padding:0.35rem;
    border-radius: 6px;
} */

.navbar-user {
  color: wheat;
}

.navbar-logout {
  text-decoration: none;
  color: white;
}

.a-link:hover {
  opacity: 0.8;
  will-change: opacity;
}
